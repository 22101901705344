import { createRequest } from '../fetcher';

/** 登录 */
export const singIn = createRequest('/customer/user/login', 'post');

/** 注册 */
export const register = createRequest(
  '/customer/user/register_account',
  'post',
);

/** 忘记密码 */
export const forgot = createRequest('/customer/user/forget_password', 'get');

/** 重置密码 */
export const reset = createRequest('/customer/user/reset_password', 'post');

/** 绑定google mail */
export const bindGoogle = createRequest('/customer/user/bind_google', 'get');

/** 绑定facebook mail */
export const bindFacebook = createRequest(
  '/customer/user/bind_facebook',
  'get',
);

/** 设置旧账号密码 */
export const setAccount = createRequest('/customer/user/set_account', 'post');

/** 发邮件验证码 */
export const sendCode = createRequest(
  '/customer/user/send_set_account_code',
  'get',
);

/** 获取 google 登陆信息 */
export const getGoogleLoginData = createRequest(
  '/customer/user/google_login',
  'get',
);
/** 获取 facebook 登陆信息 */
export const getFacebookLoginData = createRequest(
  '/customer/user/facebook_login',
  'get',
);
/** 获取当前用户信息 */
export const getLoginUserInfo = createRequest('/customer/user/userinfo', 'get');

export const login = createRequest('/admin/user/login', 'post');

/** 获取IP列表 */
export const getIPList = createRequest('/customer/shop/ip', 'get');
/** 更新当前用户信息 */
export const updateUserInfo = createRequest('/customer/user/userinfo', 'put');
/** 获取用户的收货地址列表 */
export const getAddressList = createRequest(
  '/customer/user/address_list',
  'get',
);
/** 新增收货地址 */
export const createAddress = createRequest('/customer/user/address', 'post');
/** 修改收货地址 */
export const updateAddress = createRequest(
  '/customer/user/address/{address_id}',
  'put',
);
/** 删除收货地址 */
export const deleteAddress = createRequest(
  '/customer/user/address/{address_id}',
  'delete',
);
/** 获取收获地址详情 */
export const getAddress = createRequest(
  '/customer/user/address/{address_id}',
  'get',
);

/** 订阅邮件 */
export const subscribeEmail = createRequest(
  '/customer/index/subscription',
  'post',
);

/** 获取公告列表 */
export const getNoticeList = createRequest(
  '/customer/index/bulletin_list',
  'get',
);

/** 获取厂商列表列表 */
export const getFactoryList = createRequest('/customer/shop/factory', 'get');

/** 获取 oss 配置 */
export const getOSSConfig = createRequest('/customer/index/oss_token', 'get');
