import React, { useState, useContext } from 'react';
import cls from 'classnames';
import styles from './index.module.scss';
import { Table } from '@/components/Table';
import type { Column } from '@/components/Table';
import { useQuery } from '@tanstack/react-query';
import { useCarts, useUserInfo, CartItemType } from '@/stores';
import { PayChannelFormatorMap } from '@/configs/constants';
import { Link } from 'react-router-dom';
import Picture from '@/components/Picture';
import Tag from '@/components/Tag';
import NumChange from './NumChange';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import globalContext from '@/configs/globalContext';
import CartItem from '@/components/Layout/HeaderCart/CartItem';

// import { useProductKinds } from '@/stores';

const tagMap = {
  1: {
    text: 'In stock',
    color: '#0989E2',
  },
  2: {
    text: 'Pre-sale',
    color: '#01CBB3',
  },
};

const Cart = () => {
  const { cartList, getCartList, cartPrice, createCartOrder } = useCarts();
  const { userInfo } = useUserInfo();
  const navigate = useNavigate();
  const { isMobile } = useContext(globalContext);
  const [loading, setLoading] = useState(false);
  const { isFetching } = useQuery([getCartList.name], getCartList, {
    enabled: !!userInfo,
  });
  const checkout = async () => {
    setLoading(true);
    try {
      const id = await createCartOrder({
        goods_list: cartList.map((item) => ({
          goods_id: item.id || 0,
          num: item.num || 0,
        })),
      });
      setLoading(false);
      if (!!id) {
        navigate(`/order/cart/${id}`);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const columns: Column<CartItemType>[] = [
    {
      title: 'Products',
      width: '50%',
      dataIndex: 'id',
      titleAlign: 'left',
      contentAlign: 'left',
      render: (_, record) => {
        const tagType = tagMap[record.type || 1];
        return (
          <Link
            className={styles.infoWrap}
            to={`/product/${record?.product_id}`}
            target="_blank"
          >
            <div
              className={cls(
                styles.infoCover,
                (record.stock ?? 0) <= 0 ? 'nostock' : '',
              )}
            >
              <Picture
                src={
                  record.banner
                    ? record.banner!
                    : record.img
                    ? record.img![0]
                    : ''
                }
                width={72}
                height={72}
              />
            </div>

            <div className={styles.infoMain}>
              <p className={styles.infoTitle}>{record?.title}</p>
              {record?.name && <p className={styles.infoSku}>{record?.name}</p>}
              <Tag title={tagType.text} color={tagType.color} />
            </div>
          </Link>
        );
      },
    },
    {
      title: 'Unit Price',
      width: '17%',
      dataIndex: 'id2',
      titleAlign: 'center',
      contentAlign: 'center',
      render: (_, record) => {
        return PayChannelFormatorMap.get(record.pay_channel!)?.(
          record.price ?? 0,
        );
      },
    },
    {
      title: 'Quantity',
      width: '23%',
      dataIndex: 'id3',
      titleAlign: 'center',
      contentAlign: 'center',
      render: (_, record) => {
        return (
          <NumChange
            id={record.id!}
            passNum={record.num ?? 0}
            max={record.stock ?? 0}
          />
        );
      },
    },
    {
      title: 'Subtotal',
      width: '20%',
      dataIndex: 'id4',
      titleAlign: 'center',
      contentAlign: 'center',
      render: (_, record) => {
        const total = (
          (record.price ?? 0) * Math.min(record.num ?? 0, record.stock ?? 0)
        ).toFixed(2);
        return <span className={styles.total}>{`$ ${total}`}</span>;
      },
    },
  ];
  return (
    <div className={cls('container', styles.container)}>
      <p className={styles.title}>My Cart</p>
      <div className={styles.wrap}>
        {isMobile ? (
          cartList.map((item) => (
            <CartItem key={`${item.id}${item.num}`} info={item} />
          ))
        ) : (
          <div className={styles.table}>
            <Table
              loading={isFetching}
              rowKey="id"
              data={cartList}
              columns={columns}
              gutter={10}
              bordered={true}
            />
          </div>
        )}
        <div className={styles.sumarry}>
          <p className={styles.sumarryTitle}>Order Summary</p>
          <div className={styles.desc}>
            {/* <p className={styles.textItem}>
              Total Quantity
              <span className={styles.value}>{cartTotal}</span>
            </p> */}
            <p className={styles.textItem}>
              Subtotal
              <span className={styles.value}>$ {cartPrice}</span>
            </p>
            <p className={styles.textItem}>
              Shipping
              <span className={styles.value}>TBD</span>
            </p>
            {/* <p className={styles.textItem}>
              Tax
              <span className={styles.value}>TBD</span>
            </p> */}
            <p className={styles.tip}>
              Shipping and Tax are calculated in checkout.
            </p>
          </div>
          <p className={styles.textItem}>
            Total
            <span className={styles.total}>$ {cartPrice}</span>
          </p>
          <Button
            onClick={() => checkout()}
            loading={loading}
            className={styles.btn}
            type="primary"
          >
            Checkout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
