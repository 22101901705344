import React from 'react';
import Icon from '@/components/Icon';

/**
 * 支付方式
 */
export enum PayChannel {
  /** 现金 paypal 含税费 */
  Cash = 1,
  /** 现金 信用卡(stripe被封了) */
  Stripe = 2,
  // 通过AirWallex支付
  AirWallex = 3,
  /** 金币 */
  Gold = 10,
  /** 积分就是钻石 */
  Integral = 11,
  /** 钻石抵扣完了 */
  Deduct = -1,
}

// 是否是现金支付 (用于确定物流)
export const isCashPayChannel = (payChannel: PayChannel) =>
  [PayChannel.Cash, PayChannel.Stripe, PayChannel.AirWallex].includes(
    payChannel,
  );

const commonFunction = (price?: number, icon?: string): React.ReactNode => (
  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
    {icon ? (
      <Icon
        id={icon}
        className="pay-channel-icon"
        style={{ fontSize: '1.4em', marginRight: 4 }}
      />
    ) : (
      <span className="pay-channel-icon">$</span>
    )}
    <span className="pay-channel-num">{(price ?? 0).toFixed(2)}</span>
  </span>
);

export const PayChannelLabelMap = new Map<PayChannel, string>([
  [PayChannel.Cash, '现金支付'],
  [PayChannel.Stripe, '信用卡支付'],
  [PayChannel.AirWallex, '信用卡支付'],
  [PayChannel.Gold, '金币支付'],
  // 积分是钻石
  [PayChannel.Integral, '积分兑换'],
  [PayChannel.Deduct, '钻石抵扣'],
]);

export const PayChannelFormatorMap = new Map<
  PayChannel,
  (price?: number) => React.ReactNode
>([
  [PayChannel.Cash, (price) => commonFunction(price)],
  [PayChannel.Deduct, (price) => commonFunction(price)],
  [PayChannel.Stripe, (price) => commonFunction(price)],
  [PayChannel.AirWallex, (price) => commonFunction(price)],
  [PayChannel.Gold, (price) => commonFunction(price, '#hxicon-gold')],
  [PayChannel.Integral, (price) => commonFunction(price, '#hxicon-diamond')],
]);

/**
 * 邮费费用类型
 */
export enum LogisticsType {
  /** 满五包邮 */
  OverFive = 1,
  /** 自定义邮费 */
  Normal,
}

// 金币充值，PalFee 税费，一般是4%或后端直接获取
// export const PayPalFee = 0.04;
export const PayPalFee = 0.04;
