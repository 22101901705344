import React, { useContext } from 'react';
import cls from 'classnames';
import { Link } from 'react-router-dom';

import MobileMenu from '../MobileMenu';
// import { HeaderMenus } from '../HeaderMenus';
import HeaderUserInfo from '../HeaderUserInfo';
// import { HeaderLocale } from '../HeaderLocale';
import HeaderSearch from '../HeaderSearch';

import logo from '@/assets/logo.png';
import globalContext from '@/configs/globalContext';
import styles from './index.module.scss';

export const Header: React.FC = () => {
  const { isMobile } = useContext(globalContext);
  return (
    <header className={cls(styles.header)}>
      <div className={cls('container', styles.wrap)}>
        <div className={styles.logoWrap}>
          {isMobile && <MobileMenu />}
          <Link className={cls(styles.logo)} to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className={cls(styles.extra)}>
          {/* <HeaderMenus />
          <span className={cls(styles.divider)} /> */}
          {/* <HeaderLocale /> */}
          {!isMobile && <HeaderSearch />}
          <HeaderUserInfo />
        </div>
      </div>
    </header>
  );
};
