import React from 'react';
import cls from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import { useMallParams, useProductKinds } from '@/stores';

interface Props {
  onClick?: () => void;
}

export const Menu: React.FC<Props> = ({ onClick }) => {
  const { navProductKindList } = useProductKinds();
  const { resetMallParams } = useMallParams();
  const location = useLocation();
  const handleClick = (isActived: boolean) => {
    if (!isActived) {
      onClick?.();
      // 重置商城筛选参数
      resetMallParams();
    }
  };
  return (
    <nav className={styles.menus}>
      <ul className={cls('container', styles.menuList)}>
        <li
          key="home"
          className={cls(styles.menuItem, {
            [styles.isActived]: location.pathname === '/',
          })}
        >
          <Link onClick={() => onClick?.()} to="/">
            Home
          </Link>
        </li>
        <li
          key="submissions"
          className={cls(styles.menuItem, {
            [styles.isActived]: location.pathname === '/submissions',
          })}
        >
          <Link onClick={() => onClick?.()} to="/submissions">
            Find My Statue/Figure
          </Link>
        </li>
        {!!navProductKindList &&
          navProductKindList.map((item) => {
            const ename = (item.ename || '').replace(/\s*/g, '');
            const isActived = location.pathname.indexOf(ename) > -1;
            return (
              <li
                key={item.id}
                className={cls(styles.menuItem, {
                  [styles.isActived]: isActived,
                })}
              >
                <Link
                  onClick={() => handleClick(isActived)}
                  to={`/mall/${ename}${location.search}`}
                  state={item.id}
                >
                  {item.ename}
                </Link>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};
