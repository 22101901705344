import { Suspense, useState, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/zh-cn';
import moment from 'moment';
import 'moment/locale/zh-cn';

import i18n, { localLanguage } from '@/locale';
import { queryClient } from '@/libs/react-query';
import { AppRoutes } from '@/routes';
import globalContext from '@/configs/globalContext';
import '@splidejs/splide/css';

const checkIsMobile = () => {
  return window.screen.width <= 640 || window.screen.availWidth <= 640;
};

const getLocal = (lang?: string | null) => {
  if (lang === 'zh-CN') return zhCN;
  return enUS;
};

dayjs.locale(localLanguage ?? 'en');
dayjs.extend(localizedFormat);
moment.locale(localLanguage ?? 'en');

function App() {
  const [locale, setLocal] = useState(getLocal(localLanguage));
  const [global, setGlobal] = useState({ isMobile: checkIsMobile() });

  useEffect(() => {
    const checkResize = () => {
      const flag = checkIsMobile();
      setGlobal({
        isMobile: flag,
      });
    };
    const changeLang = (lang: string) => {
      setLocal(getLocal(lang));
      dayjs.locale(localLanguage ?? 'en');
      moment.locale(localLanguage ?? 'en');
    };
    i18n.on('languageChanged', changeLang);
    window.addEventListener('resize', checkResize);
    return () => {
      i18n.off('languageChanged', changeLang);
      window.removeEventListener('resize', checkResize);
    };
  }, []);

  return (
    <Suspense fallback={<div>loading</div>}>
      <ConfigProvider locale={locale}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'production' && (
              <ReactQueryDevtools position="bottom-right" />
            )}
            <BrowserRouter basename={import.meta.env.HEX_APP_BASE_URL ?? '/'}>
              <globalContext.Provider value={global}>
                <AppRoutes />
              </globalContext.Provider>
            </BrowserRouter>
          </QueryClientProvider>
        </HelmetProvider>
      </ConfigProvider>
    </Suspense>
  );
}

export default App;
