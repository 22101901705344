import { useRef, useEffect } from 'react';
import styles from './index.module.scss';
import Icon from '@/components/Icon';
import MissingSvgImg from '@/assets/svg/missing.svg';
import cls from 'classnames';
import { isInViewport, getRandomInt, hasQueryString } from '@/utils';

const devicePixelRatio = window.devicePixelRatio || 1;
interface Props {
  src: string;
  lazy?: boolean;
  width?: number;
  height?: number;
  className?: string;
  clickFun?: (e: React.MouseEvent) => void;
  // 是否强制让图片高度等于宽度
  heightEqualWidth?: boolean;
}

const Index: React.FC<Props> = ({
  src,
  width,
  height,
  heightEqualWidth,
  lazy = true,
  className,
  clickFun,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const temSrc = lazy ? MissingSvgImg : src;
  const imgStyle = {
    width: width || '100%',
    height: height || 'auto',
  };
  let iconStyle = {};
  if (!src) {
    if (heightEqualWidth) {
      iconStyle = {
        width: '100%',
        height: 'fit-content',
      };
    } else {
      iconStyle = {
        fontSize: `${
          !!width && !!height ? Math.min(width, height) : width || height || 16
        }px`,
      };
    }
  }
  useEffect(() => {
    if (lazy && imgRef.current) {
      const width = imgRef.current.offsetWidth || imgRef.current.clientWidth;
      const height = imgRef.current.offsetHeight || imgRef.current.clientHeight;
      const suffix =
        width > 0 || height > 0
          ? `${
              hasQueryString(imgRef.current.dataset.src!) ? '&' : '?'
            }x-oss-process=image/resize,m_lfit${
              height > 0 ? `,h_${Math.ceil(height * devicePixelRatio)}` : ''
            }${width > 0 ? `,w_${Math.ceil(width * devicePixelRatio)}` : ''}`
          : '';
      try {
        const loadingNow = isInViewport(imgRef.current);
        if (loadingNow) {
          imgRef.current.src = `${imgRef.current.dataset.src}${suffix}`;
        } else {
          const time = getRandomInt(500, 800);
          setTimeout(() => {
            if (
              imgRef.current &&
              imgRef.current.dataset &&
              imgRef.current.dataset.src
            ) {
              imgRef.current.src = `${imgRef.current.dataset.src}${suffix}`;
            }
          }, time);
        }
      } catch (error) {
        imgRef.current.src = `${imgRef.current.dataset.src}${suffix}`;
      }
    }
  }, [lazy]);
  const handleError = (e: any) => {
    if (e && e.target) {
      e.target.src = MissingSvgImg;
      e.target.removeAttribute('loading');
    }
  };
  return (
    <div
      className={cls(
        styles.wrap,
        {
          'can-click': !!clickFun,
          [styles.equal]: heightEqualWidth,
        },
        className,
      )}
      onClick={(e) => clickFun && clickFun(e)}
    >
      {!!src ? (
        <img
          ref={imgRef}
          className={cls(styles.img)}
          src={temSrc}
          loading="lazy"
          data-src={src}
          onError={handleError}
          {...imgStyle}
        />
      ) : (
        <Icon id="#hxicon-missingimg" style={iconStyle} />
      )}
    </div>
  );
};

export default Index;
